import {
  get,
  post,
  downFile
} from 'common/request'

export default {
  // 分页查询
  list: args => get('/appVersion/list', args),
  // 获取
  get: args => get(`/appVersion/get?id=${args}`),
  // 保存
  save: args => post('/appVersion/save', args),
  // 删除
  del: args => get(`/appVersion/del?id=${args}`),
  // 获取字典数据
  dictData: args => get('/dict-data/getByDicKind', args),
  // 根据用户id查询部门信息
  deptUser: args => get(`/dept/dept-user${args}`),
  // 导出
  exportXls: args => downFile('/travel/export', args),
  // 文件上传
  uploadFile: args => post('/attachFile/upload', args),
  // 发布
  release: args => get(`/appVersion/release?id=${args}`),
  // 回退
  back: args => get(`/appVersion/back?id=${args}`),
  // 撤销
  revoke: args => get(`/appVersion/revoke?id=${args}`)
}
