<template>
  <div>
    <ax-table
      ref="roleTable"
      :show-search="true"
      :searchForm="searchForm"
      :searchActions='searchActions'
      :columns="columns"
      :dataSourceApi="api.list"
      :dataSourceParams="dataSourceParams"
      :sqlParams="sqlParams"
      :clickable="false"
      @change="changeValue"
      @export="expor"
      @pop-confirm="popConfirm"
      @on-search-click="onSearchClick"
      @action-column-click="actionColumnClick"
    >
    </ax-table>
    <a-modal
      @cancel="handleCancel"
      @ok="handleOk"
      :visible="visible"
      title="版本上传"
    >
      <ax-form
        ref="formBox1"
        :formBuilder="formBuilder"
        @pop-confirm="popConfirm"
      >
        <template slot="fileId">
          <a-upload
            ref="upload"
            name="file"
            :multiple="false"
            :file-list="fileList1"
            :customRequest="handleUpload"
            @change="handleChange"
          >
            <a-button size="small">
              <a-icon type="upload" />上传附件
            </a-button>
            <div
              v-if="fileList2.length>0"
              class="preview"
            >
              <a-icon
                class="paper-clip"
                type="paper-clip"
              />
              <span class="file">{{fileList2[0].originalFileName}}</span>
            </div>
          </a-upload>
        </template>
      </ax-form>
    </a-modal>
  </div>
</template>

<script>

import { initGridFormData, isEmpty } from '@/common/tools'
import api from './api'

const searchForm = [
  { label: 'appId', type: '', model: 'appid', options: {}, formItem: {}, col: { span: 6 } },
  { label: '安装包名称', type: '', model: 'packageName', options: {}, formItem: {}, col: { span: 6 } },
  { label: '安装包类型', type: 'select', model: 'packageType', options: {}, formItem: {}, col: { span: 6 } },
  { label: '系统类型', type: 'select', model: 'equipmentType', options: {}, formItem: {}, col: { span: 6 } }
]
export default {
  components: {},
  data () {
    return {
      api,
      visible: false,
      leaveTypeList: [],
      fileList1: [],
      fileList2: [],
      equipmentTypeList: [],
      packageTypeList: [],
      startTime: '',
      endTime: '',
      uoloadResult: false,
      dateRangeKeys: [{ startKey: 'startTime', endKey: 'endTime' }],
      searchForm: initGridFormData(searchForm, { layout: 'horizontal', labelWidth: 90 }),
      searchActions: [
        { name: 'search', text: '查询' },
        { name: 'reset', text: '重置' },
        { name: 'add', text: '发布版本' }
      ],
      sqlParams: { like: ['packageName', 'appid'] },
      columns: this.$ax.tools.initColumn([
        {
          title: '版本说明',
          dataIndex: 'versionDescription',
          width: '25%',
          align: 'left'
        },
        {
          title: 'APPID',
          dataIndex: 'appid',
          width: '10%'
        },
        {
          title: '安装包名称',
          dataIndex: 'packageName',
          width: '10%'
        },
        {
          title: '更新类型',
          dataIndex: 'packageType_dictText',
          width: '8%'
        },
        {
          title: '系统类型',
          dataIndex: 'equipmentType_dictText',
          width: '10%'
        },
        {
          title: '是否最新版本',
          dataIndex: 'isCurrentVersion_dictText',
          width: '10%'
        },
        {
          title: '版本号',
          dataIndex: 'packageVersion',
          width: '7%'
        },
        {
          title: '创建日期',
          dataIndex: 'createTime',
          width: '15%'
        }
      ], true, {
        width: 100,
        actions: [
          { props: record => { return { text: '发布', name: 'release', type: 'primary', link: true, popDisabled: false, title: '确认发布？', disabled: record.isCurrentVersion !== '0' } } },
          { props: record => { return { text: '编辑', name: 'edit', type: 'primary', link: true, popDisabled: false, disabled: record.isCurrentVersion !== '0' } } },
          { props: record => { return { text: '撤销', name: 'revoke', type: 'primary', link: true, popDisabled: false, title: '确定要撤销吗?', disabled: record.isCurrentVersion !== '1' } } },
          // { props: record => { return { text: '版本回退', name: 'back', type: 'primary', link: true, popDisabled: false, title: '确认回退?', disabled: record.isCurrentVersion !== '1' } } },
          { props: record => { return { text: '删除', name: 'del', type: 'primary', link: true, popDisabled: false, title: '确认删除？', disabled: record.isCurrentVersion !== '0' } } }
        ]
      }
      ),
      dataSourceParams: {
      },
      formBuilder: initGridFormData(
        [
          {
            label: 'id',
            type: 'input',
            model: 'appid',
            options: {
              placeholder: '请输入appId',
              allowClear: true,
              hide: true
            },
            rules: [{ required: false, message: 'appid不允许为空' }],
            formItem: { colon: false }
          },
          {
            label: 'APPID',
            type: 'input',
            model: 'appid',
            options: {
              placeholder: '请输入appId',
              allowClear: true
            },
            rules: [{ required: true, message: 'appid不允许为空' }],
            formItem: { colon: false },
            col: { span: 24 }
          },
          {
            label: '名称',
            type: 'input',
            model: 'packageName',
            options: {
              placeholder: '请输入安装包',
              allowClear: true
            },
            rules: [{ required: true, message: '安装包不允许为空' }],
            formItem: { colon: false },
            col: { span: 24 }
          },
          {
            label: '版本号',
            type: 'input',
            model: 'packageVersion',
            options: {
              placeholder: '请输入版本号',
              allowClear: true
            },
            rules: [{ required: true, message: '请输入版本号' }],
            formItem: { colon: false },
            col: { span: 24 }
          },
          {
            label: '安装包类型',
            type: 'select',
            model: 'packageType',
            options: {
              placeholder: '请选择安装包类型',
              allowClear: true
            },
            rules: [{ required: true, message: '请选择安装包类型' }],
            formItem: { colon: false },
            col: { span: 24 }
          }, {
            label: '安装包类型',
            type: 'select',
            model: 'equipmentType',
            options: {
              placeholder: '请选择设备类型',
              allowClear: true
            },
            rules: [{ required: true, message: '请选择设备类型' }],
            formItem: { colon: false },
            col: { span: 24 }
          },
          {
            label: '版本说明',
            type: 'textarea',
            model: 'versionDescription',
            options: {
              placeholder: '请输入版本说明',
              allowClear: true
            },
            rules: [{ required: false, message: '请选择设备类型' }],
            formItem: { colon: false },
            col: { span: 24 }
          },
          {
            label: '文件上传',
            type: '',
            model: 'fileId',
            options: {},
            rules: [{ required: true, message: '请上传版本文件' }],
            formItem: { colon: false },
            col: { span: 24 }
          }
        ],
        {
          layout: 'horizontal',
          labelWidth: 90
        }
      )
    }
  },
  async created () {
    await this.equipmentTypeDic()
    await this.packageTypeDic()
  },
  filters: {
    // 状态过滤
    statusFilter (val) {
      if (val === '0') {
        return '已提交'
      } else if (val === '1') {
        return '审核中'
      } else if (val === '2') {
        return '审核通过'
      } else {
        return '驳回'
      }
    }
  },
  methods: {
    getList () {
      this.$refs.roleTable.getDataSource()
    },
    popConfirm ({ record, btn: { name } }) {
      if (name === 'release') {
        api.release(record.id).then(res => {
          this.$message.success('发布成功')
          this.getList()
        })
      } else if (name === 'del') {
        api.del(record.id).then(res => {
          this.$message.success('删除成功')
          this.getList()
        })
      } else if (name === 'back') {
        api.back(record.id).then(res => {
          this.$message.success('回退成功')
          this.getList()
        })
      } else if (name === 'edit') {
        this.getData(record.id)
        this.visible = true
      } else if (name === 'revoke') {
        api.revoke(record.id).then(res => {
          this.$message.success('撤销成功')
          this.getList()
        })
      }
    },
    async equipmentTypeDic () {
      await api.dictData({ dicKind: 'equipment_type' }).then(res => {
        const options = res.data.map(res => { return { label: res.dicDesc, value: res.dicCode } })
        this.equipmentTypeList = options
        this.$refs.roleTable.$children[0].$refs.searchForm.setFormItemProp('equipmentType', { options: { options, allowClear: true } })
      })
    },
    async packageTypeDic () {
      await api.dictData({ dicKind: 'package_type' }).then(res => {
        const options = res.data.map(res => { return { label: res.dicDesc, value: res.dicCode } })
        this.packageTypeList = options
        this.$refs.roleTable.$children[0].$refs.searchForm.setFormItemProp('packageType', { options: { options, allowClear: true } })
      })
    },
    changeValue (value, key) {

    },
    changeValueForm (value, key) {
    },
    // 行点击
    handleClick (record) {
      this.$router.push({
        path: '/oa/leave/details',
        query: { reportId: record.id }
      })
    },
    actionColumnClick (args) {

    },

    expor () {
      const values = JSON.parse(JSON.stringify(this.searchForm.formValues))
      if (values.leaveRoommate !== undefined && values.leaveRoommate !== '') {
        values.leaveRoommate = '*' + values.leaveRoommate + '*'
      }
      api.exportXls({
        fileName: 'OA自动化办公请假申请列表.xls',
        params: values
      })
    },
    onSearchClick ({ btn, formValues }) {
      switch (btn.name) {
        case 'add':
          this.visible = true
          this.$nextTick(() => {
            this.$refs.formBox1.setOptions(['equipmentType'], 'options', this.equipmentTypeList)
            this.$refs.formBox1.setOptions(['packageType'], 'options', this.packageTypeList)
          })
          break
      }
    },
    handleOk () {
      this.$refs.formBox1.form.validateFields(async (errors, values) => {
        if (!errors) {
          const report = this.$refs.formBox1.getFieldsValue()
          if (!this.uoloadResult) {
            this.$message.warning('文件正在上传中')
            return false
          }
          if (!isEmpty(this.fileData)) {
            report.packageUrl = this.fileData.url
            report.fileId = this.fileData.id
            report.isCurrentVersion = '0'
            report.fileName = this.fileData.originalFileName
          } else {
            this.$message.error('请上传版本文件')
            return false
          }

          api.save(report).then((res) => {
            this.$message.success('操作成功!')
            this.handleCancel()
            this.getList()
          })
        }
      })
    },
    handleCancel () {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.formBox1.resetFields()
        this.$refs.formBox1.setFieldsValue({ id: '' })
        this.fileList1 = []
        this.fileList2 = []
      })
    },
    handleUpload ({ file }) {
      const formData = new FormData()
      formData.append('file', file)
      api.uploadFile(formData).then((res) => {
        if (res.status === 200) {
          this.$message.success('上传成功')
          this.fileData = res.data
          this.uoloadResult = true
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleChange (info) {
      const fileList = [...info.fileList]
      this.fileList2 = []
      this.fileList1 = fileList.slice(-1)
      info.file.status = 'done'
    },
    async getData (id) {
      await api.get(id).then((res) => {
        this.$refs.formBox1.setFieldsValue(res.data)
        this.fileList2 = []
        res.data.originalFileName = res.data.fileName
        this.fileList2.push(res.data)
        this.fileData = res.data
      })
      this.$refs.formBox1.setOptions(['equipmentType'], 'options', this.equipmentTypeList)
      this.$refs.formBox1.setOptions(['packageType'], 'options', this.packageTypeList)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-table-thead {
  background: rgba(100, 191, 160, 0.5);
}
</style>
